
import Slider from './components/Slider';
import React from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';
import Layout from './components/Layout';
import About from './components/AboutUs';
import CarList from './components/Categories';
import c from "./app.module.scss"
function App() {
  const { t } = useTranslation();



  return (
    <div className={c.bcg}>

  <Layout>
  <Slider/>

 <CarList></CarList>
   <About></About> 

  </Layout>
    </div>
  );
}

export default App;
