import React from 'react'
import { Carousel } from 'antd';
import Slider1 from "../../images/Slider1.jpg"
import sliderm from "../../images/sliderm.jpeg"

import slider from "../../images/slider.jpg"
import sliderneww from "../../images/sliderr.jpg"

const contentStyle = {
  height: '80vh',
  width:'100%',
  margin:'0 auto',
  objectFit: "cover",
  textAlign: 'center',
  borderRadius:"4px"
};

function Slider() {
  return (
    <div>
      <Carousel autoplay>
        <div>
          <img style={contentStyle} src={slider} alt="isuzu photo" /> 
        </div>
        <div>
          <img style={contentStyle} src={Slider1} alt="isuzu photo" /> 
        </div>
        <div>
          <img style={contentStyle} src={sliderneww} alt="isuzu photo" /> 
        </div>
        <div>
          <img style={contentStyle} src={sliderm} alt="isuzu photo" /> 
        </div>
      </Carousel>
    </div>
  )
}

export default Slider
