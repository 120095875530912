import React from "react";

import c from "./styles.module.scss";
import { useTranslation, initReactI18next } from "react-i18next";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 51.5074,
  lng: -0.1278,
};

function Footer() {
  const { t } = useTranslation();
  return (
    <div id="footer" className={c.contactus}>
      <div className={c.contactinfo}>
        <h2 className={c.title}>{t("contacts")}</h2>

        <p>
          {t("address")} <td />
          {t("address2")}
          <hr />
          <a href="tel:+998914098844"> {t("contact")}:+99891-409-8844</a>
          <hr />
          <a href="tel:+998987009919">{t("contact")}:+99898-700-9919</a>
          <hr />
        </p>
        <div className={c.icons}>
          <a
            target="_blank"
            href="https://www.instagram.com/isuzubukhara?igsh=MTA1YWN0OG10NjlyeA=="
          >
            <i
              class="fa-brands fa-instagram"
              style={{ fontSize: "30px", color: "black" }}
            ></i>
          </a>
          <a href="https://t.me/truckservicecenter" target="_blank">
            {" "}
            <i
              class="fa-brands fa-telegram"
              style={{ fontSize: "30px", color: "black" }}
            ></i>
          </a>

          <a href="tel:+998904098844" target="_blank">
            {" "}
            <i
              class="fa-solid fa-phone"
              style={{ fontSize: "28px", color: "black" }}
            ></i>
          </a>
        </div>
      </div>
      <div className={c.map}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3065.849096038646!2d64.4198111!3d39.787941800000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f5008a55fa69dfb%3A0x63e8d9658b814723!2s69%20Gizhduvanskaya%20Ulitsa%2C%20Bukhara!5e0!3m2!1sen!2s!4v1680470629389!5m2!1sen!2s"
          style={{
            width: "600",
            height: "450",
            style: "border:0;",
            allowfullscreen: "",
            loading: "lazy",
          }}
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

export default Footer;
