import React, { useState } from 'react';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import c from "./styles.module.scss";
import ru from "../../images/ru.jpg"
import uz from "../../images/uz.jpg"
// Initialize i18next instance
i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['uz', 'ru'],
    fallbackLng: 'uz',
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false,
    },
  });

// Example component for navbar
function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  // Handler for language switch button
  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
  };

  // Toggle menu state when menu button is clicked
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={c.navbar}>
      {/* Logo */}
      <div className={c.logo}>
      <h1>TSC|<span>TRUCK SERVICE CENTER</span>
      </h1>
      <p>{t('navbarlong')}</p>
      </div>
    


      {/* Menu items */}
      <ul className={`c.navbar-menu ${isMenuOpen ? 'open' : ''}`}>
        <li className={c.navmenuitem}>
          <a className={c.item}  href='#about'>{t('about')}</a>
        </li>
     
        <li className={c.navmenuitem}>
        
          <a className={c.item} href='#footer'>{t('contacts')}</a>
        </li>
        <li className={c.navmenuitem}>
         
          <a className={c.item} href="#categories">{t('catalogue')}</a>
        </li>
      </ul>
      <div className={c.navlang}>
           
           <button className={c.languageButton} onClick={() => handleLanguageChange('uz')}>
            <img src={uz}></img>
               Uz
           </button>
           
           <button className={c.languageButton} onClick={() => handleLanguageChange('ru')}>
           <img src={ru}></img>
               Ru
           </button>
           {/* <button className={c.languageButton} onClick={() => handleLanguageChange('en')}>
             Eng
           </button> */}
         </div>
    </nav>
  );
}

export default Navbar;
